import React, { useState, useEffect, useContext } from "react";
import { doc, updateDoc, increment, arrayUnion, serverTimestamp, arrayRemove, deleteField } from "firebase/firestore";
import { Groupsdb } from "../firebase";
import { AuthContext } from "../context/AuthContext";
import { signOut } from "firebase/auth";
import { auth } from '../firebase';
import { storage } from '../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import FileInput from "../Images/folder.png";
import moment from 'moment';
import swal from '@sweetalert/with-react';
import LoadingBar from 'react-top-loading-bar';

const GroupSettings = (props) => {
    const [adminPopup, setAdminPopup] = useState(false);
    const { currentUser } = useContext(AuthContext);
    const [err, setErr] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorCode, setErrorCode] = useState("");
    const [text, setText] = useState("");
    const [progresspercent, setProgressPercent] = useState();
    const [pfpFile, setPFPFile] = useState(null);

    const GroupMembers = []
    const GroupMembersData = []

    if (props.SendMsgCount === 0) {
        document.title = "Drake Chat • " + props.SelectedGroup.Name + " - Settings";
    } else {
        document.title = "(" + props.SendMsgCount + ") Drake Chat • " + props.SelectedGroup.Name + " - Settings";
    }

    const [combinedCurrentDateTime, setCombinedCurrentDateTime] = useState(null)

    useEffect(() => {
        const interval = setInterval(() => {
            var current = new Date();
            var CurrentTime = current.toLocaleString([], { hour12: true, hour: '2-digit', minute: '2-digit' });
            var CurrentDate = current.toLocaleDateString();
            setCombinedCurrentDateTime(CurrentDate + " " + CurrentTime)
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const startTime = moment(props.SelectedGroupData.DataChangedDate.displayNameChangedOn, 'DD/MM/YYYY hh:mm a');
    const endTime = moment(combinedCurrentDateTime, 'DD/MM/YYYY hh:mm a');
    const duration = moment.duration(endTime.diff(startTime));
    const days = parseInt(duration.asDays());
    const ChangingDataDateTimeD = days

    const startTimeDP = moment(props.SelectedGroupData.DataChangedDate.DisplayPictureChangedOn, 'DD/MM/YYYY hh:mm a');
    const endTimeDP = moment(combinedCurrentDateTime, 'DD/MM/YYYY hh:mm a');
    const durationDP = moment.duration(endTimeDP.diff(startTimeDP));
    const daysDP = parseInt(durationDP.asDays());
    const ChangingDataDateTimeDDP = daysDP

    const AddMembers = async (data) => {
        const CombinedId = data.uid + props.SelectedGroupData.id
        await updateDoc(doc(Groupsdb, "Groups", props.SelectedGroupData.id), {
            MembersCount: increment(1),
            Members: arrayUnion({
                id: data.uid,
                displayName: data.displayName,
                JoinedOn: combinedCurrentDateTime,
                photoURL: data.photoURL,
            }),
        });
        await updateDoc(doc(Groupsdb, "GroupsData", data.uid), {
            [CombinedId]: {
                date: serverTimestamp(),
                id: props.SelectedGroupData.id,
                Name: props.SelectedGroupData.Name,
                displayPic: props.SelectedGroupData.displayPic,
                ChatID: CombinedId,
            },
        });
    }

    const RemoveMember = async (data) => {
        if (currentUser.displayName === props.SelectedGroupData.Admin) {
            const CombinedId = data.id + props.SelectedGroupData.id
            await updateDoc(doc(Groupsdb, "Groups", props.SelectedGroupData.id), {
                MembersCount: increment(-1),
                Members: arrayRemove({
                    id: data.id,
                    displayName: data.displayName,
                    JoinedOn: data.JoinedOn,
                    photoURL: data.photoURL,
                }),
            });
            await updateDoc(doc(Groupsdb, "GroupsData", data.id), {
                [CombinedId]: deleteField()
            });
        } else {
            swal({
                text: "You Need To Be Admin To Remove Users!!",
                icon: "warning",
                dangerMode: true,
            })
        }
    }

    const LeaveGroup = async (data) => {
        const CombinedId = data.id + props.SelectedGroupData.id
        swal({
            title: "Are You Sure?",
            text: "You Want To Leave This Group",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willLeave) => {
            if (willLeave) {
                swal("Group Left", {
                    icon: "success",
                });
                updateDoc(doc(Groupsdb, "Groups", props.SelectedGroupData.id), {
                    MembersCount: increment(-1),
                    Members: arrayRemove({
                        id: data.id,
                        displayName: data.displayName,
                        JoinedOn: data.JoinedOn,
                        photoURL: data.photoURL,
                    }),
                });
                updateDoc(doc(Groupsdb, "GroupsData", data.id), {
                    [CombinedId]: deleteField()
                });
                props.rcw()
                props.onChange("GROUPS")
            }
        });
    }

    const RenameGroup = () => {
        if (ChangingDataDateTimeD > 14) {
            swal({
                title: "Warning",
                text: "You Can Rename Your Group Once In Two Weeks",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willUpdate) => {
                if (willUpdate) {
                    swal({
                        text: 'Please Enter A New Name For This Group',
                        content: "input",
                        button: {
                            text: "CHANGE"
                        },
                    }).then(name => {
                        if (name.length > 0) {
                            updateDoc(doc(Groupsdb, "Groups", props.SelectedGroupData.id), {
                                Name: name,
                            });
                            GroupMembersData?.map(user => (
                                updateDoc(doc(Groupsdb, "GroupsData", user.id), {
                                    [user.id + props.SelectedGroupData.id]: {
                                        date: serverTimestamp(),
                                        id: props.SelectedGroupData.id,
                                        Name: name,
                                        displayPic: props.SelectedGroupData.displayPic,
                                        ChatID: user.id + props.SelectedGroupData.id,
                                    }
                                })
                            ))
                            swal("Group Renamed", {
                                icon: "success",
                            });
                        }
                    })
                }
            });
        } else {
            // var DateTimeChanging
            // if (ChangingDataDateTimeD === 1) {
            //     DateTimeChanging = ChangingDataDateTimeD + " Day"
            // } else if (ChangingDataDateTimeD === 0) {
            //     DateTimeChanging = "1 Day"
            // } else {
            //     DateTimeChanging = ChangingDataDateTimeD + " Days"
            // }
            swal(`You Can't Rename Your Group Before Two Weeks`);
        }
    }

    const ChangePFP = async (e) => {
        e.preventDefault();
        if (ChangingDataDateTimeDDP > 14) {
            swal({
                title: "Warning",
                text: "You Can Change Display Picture Of Your Group Once In Two Weeks",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willUpload) => {
                if (willUpload) {
                    if (pfpFile) {
                        setLoading(true);
                        e.preventDefault();
                        const File = e.target[0].files[0];

                        try {
                            const storageRef = ref(storage, "GroupDisplayPicture/" + props.SelectedGroupData.id);
                            const uploadTask = uploadBytesResumable(storageRef, File);
                            uploadTask.on(
                                'state_changed',
                                (snapshot) => {
                                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                    const roundedprogress = Math.round(progress);
                                    setProgressPercent(roundedprogress);
                                },
                                (error) => {
                                    setErr(true);
                                    setLoading(false);
                                    setErrorCode(error.code);
                                    // setErrorMessage(error.message);
                                    if (error.code === "auth/requires-recent-login") {
                                        swal("Authorization Credentials Expired, Signing Out. Please Sign In Again")
                                        signOut(auth)
                                        window.location.reload()
                                    }
                                },
                                () => {
                                    getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                                        await updateDoc(doc(Groupsdb, "Groups", props.SelectedGroupData.id), {
                                            displayPic: downloadURL,
                                        });
                                        GroupMembersData?.map(user => (
                                            updateDoc(doc(Groupsdb, "GroupsData", user.id), {
                                                [user.id + props.SelectedGroupData.id]: {
                                                    date: serverTimestamp(),
                                                    id: props.SelectedGroupData.id,
                                                    Name: props.SelectedGroupData.Name,
                                                    displayPic: downloadURL,
                                                    ChatID: user.id + props.SelectedGroupData.id,
                                                }
                                            })
                                        ))
                                        swal("Group Display Picture Changed", {
                                            icon: "success",
                                        });
                                    });
                                    setLoading(false);
                                    setText("");
                                })
                        } catch (error) {
                            setErr(true);
                            setLoading(false);
                            setErrorCode(error.code);
                            // setErrorMessage(error.message);
                            if (error.code === "auth/requires-recent-login") {
                                swal("Authorization Credentials Expired, Signing Out. Please Sign In Again")
                                signOut(auth)
                                window.location.reload()
                            }
                        }
                    }
                }
            });
        } else {
            swal(`You Can't Rename Your Group Before Two Weeks`);
        }
    }

    const ChangeAdmin = (val) => {
        if (currentUser.displayName === props.SelectedGroupData.Admin) {
            swal({
                title: "Warning",
                text: "If You Make Some One Else Admin Of This Group, You Will Loose All Your Powers As An Administrator",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willChange) => {
                if (willChange) {
                    updateDoc(doc(Groupsdb, "Groups", props.SelectedGroupData.id), {
                        Admin: val.displayName,
                    });
                    swal(`Admin Changed To ${val.displayName}`, {
                        icon: "success",
                    });
                    setAdminPopup(false);
                }
            });
        } else {
            swal(`You Can't Rename Your Group Before Two Weeks`);
        }
    }

    const Group = () => {
        props.onChange("GROUPS")
        // setForwardMsg(false)
        // setToolsInput("Input")
    }

    const handleImageChange = e => {
        const validExtensions = ["image/jpg", "image/png", "image/jpeg"]
        if (!validExtensions.includes(e.target.files[0].type)) {
            swal("File Format Not Supported")
            setText("");
            setLoading(false);
            setPFPFile(null);
            return false
        } else {
            if (e.target.files[0].size > 10 * 1024 * 1024) {
                swal("File Size Should Be Less Than 10 MB")
                setText("");
                setLoading(false);
                setPFPFile(null);
                return false
            } else {
                setText(e.target.files[0].name)
                setPFPFile(e.target.files[0])
            }
        }
    }

    const PopupAdmin = () => {
        setAdminPopup(true)
    }

    props.SelectedGroupData.Members?.map(m => (
        GroupMembers.includes(m.displayName) === false ? GroupMembers.push(m.displayName) : null
    ))

    props.SelectedGroupData.Members?.map(m => (
        GroupMembersData.includes(m) === false ? GroupMembersData.push(m) : null
    ))

    return (
        <>
            {loading && <LoadingBar color='linear-gradient(45deg,#ffbc00,#00d0ff ,#ff0058)' shadow={true} progress={progresspercent} onLoaderFinished={() => setProgressPercent(0)} height={4} style={{ "border-radius": "44px" }} />}
            {props.scw === false ? null :
                <section className=" w-full absolute z-20 bg-transparent backdrop-blur-2xl rounded-2xl h-full left-0 top-0 p-4 overflow-hidden lg:block lg:relative lg:w-3/5 xl:w-3/4" id="chat">
                    <header className="flex justify-between px-2 md:px-4 lg:px-6">
                        <div className="flex gap-2 items-center">
                            <span className="my-auto cursor-pointer" id="close-chat" onClick={Group}>
                                <i className="fi fi-rr-angle-small-left flex bg-violet-200 dark:bg-slate-800 dark:text-white p-2 rounded-lg text-violet-700 transition duration-500 hover:shadow-xl active:shadow-inner"></i>
                            </span>
                            <div className="text-xl ml-4">
                                <h2 className="font-extrabold whitespace-nowrap dark:text-white">SETTINGS</h2>
                            </div>
                        </div>
                    </header>
                    <main className="bg-gray-200 dark:bg-slate-800 w-full h-[90%] mt-4 p-2 pr-2 rounded-2xl md:p-4 lg:p-6">
                        <div className="w-full h-full relative flex items-center text-center flex-col gap-4 overflow-y-scroll">
                            <h1 className="text-4xl underline text-rose-600 font-black mt-4">ADD MEMBERS</h1>
                            <div className="flex flex-col mt-10 bg-gray-400 p-4 rounded-2xl w-full">
                                <ul className="mt-4 flex flex-col gap-4 overflow-y-scroll overflow-x-hidden h-[90%] w-full" id="messages-list">
                                    {/* {props.SelectedGroupData.Members.map(m => ( */}
                                    <>
                                        {Object.entries(props.chatdata)?.sort((a, b) => b[1].date - a[1].date).map((user) => (
                                            <>
                                                {GroupMembers.includes(user[1].userInfo.displayName) === false
                                                    ?
                                                    <>
                                                        <div key={user[0]}>
                                                            <li id="SelectUser" className="flex relative items-center gap-2 overflow-x-hidden bg-white dark:bg-slate-800 p-2 rounded-3xl transition duration-300 hover:shadow-3xl hover:z-10 active:shadow-inner">
                                                                <picture className="relative flex h-12 aspect-square">
                                                                    <img className="h-full w-full object-cover block rounded-full" src={user[1].userInfo.photoURL} alt="user" />
                                                                </picture>
                                                                <div className="flex flex-row w-full justify-between items-center text-center">
                                                                    <header className="text-xl font-bold dark:text-white">
                                                                        {user[1].userInfo.displayName}
                                                                    </header>
                                                                    <button className="flex bg-lime-500 p-2 text-white rounded-xl cursor-pointer font-extrabold" onClick={() => AddMembers(user[1].userInfo)}>
                                                                        ADD
                                                                    </button>
                                                                </div>
                                                            </li>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div key={user[0]}>
                                                            <li id="SelectUser" className="flex relative items-center gap-2 overflow-x-hidden bg-white dark:bg-slate-800 p-2 rounded-3xl transition duration-300 hover:shadow-3xl hover:z-10 active:shadow-inner">
                                                                <picture className="relative flex h-12 aspect-square">
                                                                    <img className="h-full w-full object-cover block rounded-full" src={user[1].userInfo.photoURL} alt="user" />
                                                                </picture>
                                                                <div className="flex flex-row w-full justify-between items-center text-center">
                                                                    <header className="text-xl font-bold dark:text-white">
                                                                        {user[1].userInfo.displayName}
                                                                    </header>
                                                                    <button className="flex bg-gray-400 p-2 text-slate-800 rounded-xl cursor-not-allowed font-extrabold" disabled>
                                                                        ADDED
                                                                    </button>
                                                                </div>
                                                            </li>
                                                        </div>
                                                    </>
                                                }
                                            </>
                                        ))}
                                    </>
                                    {/* ))} */}
                                </ul>
                            </div>
                            <h1 className="text-4xl underline text-rose-600 font-black mt-4">GROUP INFO</h1>
                            <div className="flex flex-col mt-10 bg-gray-400 p-10 rounded-2xl w-full items-center justify-center">
                                <h1 className="text-3xl text-teal-400 font-black underline uppercase">Group Name</h1>
                                <h1 className="text-2xl text-slate-800 dark:text-white font-extrabold mt-4">{props.SelectedGroupData.Name}</h1>
                                {currentUser.displayName === props.SelectedGroupData.Admin ? <button className="cursor-pointer text-slate-800 text-xl uppercase font-extrabold rounded-full bg-red-400 p-2 mt-8" onClick={RenameGroup}>CHANGE GROUP NAME</button> : null}
                            </div>
                            {currentUser.displayName === props.SelectedGroupData.Admin &&
                                <div className="flex flex-col mt-10 bg-gray-400 p-10 rounded-2xl w-full items-center justify-center">
                                    <h1 className="text-3xl text-teal-400 font-black underline uppercase">Group Display Picture</h1>
                                    <picture className="relative flex h-14 aspect-square w-14 mt-8">
                                        <img className="h-full w-full object-cover block rounded-full" src={props.SelectedGroupData.displayPic} alt="groupDP" />
                                    </picture>
                                    <form onSubmit={ChangePFP} className="justify-center align-center text-center items-center flex flex-col mt-8 p-8 w-full" style={{ "border-radius": "44px", "background-color": "rgba(0,0,0,0.2)", "backdrop-filter": "blur('44px')" }}>
                                        <input accept="image/png, image/jpeg" type="file" placeholder="Enter Your Profile Picture" id="File" style={{ display: "none" }} onChange={(e) => handleImageChange(e)} className="bg-transparent backdrop-blur-3xl" />
                                        <label htmlFor="File" className="justify-center flex flex-col items-center text-center">
                                            <img src={FileInput} style={{ height: '40px', width: '40px' }} alt="Select A Group DP" className="cursor-pointer" />
                                            <span className="font-extrabold text-white text-xl uppercase mt-2 cursor-pointer">Upload A Group Display Picture</span>
                                        </label>
                                        <textbox readonly className="text-slate-800 dark:text-white">{text}</textbox>
                                        {loading && <button disabled="true" className="cursor-pointer text-slate-800 text-xl uppercase font-extrabold rounded-full bg-red-400 p-2 mt-8">CHANGING</button>}
                                        {!loading &&
                                            <button className="cursor-pointer text-slate-800 text-xl uppercase font-extrabold rounded-full bg-red-400 p-2 mt-8">CHANGE PROFILE PHOTO</button>}
                                        {!loading && err && <span style={{ color: 'red' }}>Something Went Wrong!!</span>}
                                        {!loading && err && <span style={{ color: 'red' }}>{errorCode}</span>}
                                    </form>
                                </div>
                            }
                            {!adminPopup
                                ? currentUser.displayName === props.SelectedGroupData.Admin &&
                                <>
                                    <div className="flex flex-col mt-20 bg-gray-400 p-10 rounded-2xl w-full items-center justify-center">
                                        <h1 className="text-3xl text-teal-400 font-black underline uppercase">Admin</h1>
                                        <h1 className="text-2xl text-slate-800 dark:text-white font-extrabold mt-4">{props.SelectedGroupData.Admin}</h1>
                                        {!adminPopup ? currentUser.displayName === props.SelectedGroupData.Admin ? <button className="cursor-pointer text-slate-800 text-xl uppercase font-extrabold rounded-full bg-red-400 p-2 mt-8" onClick={PopupAdmin}>CHANGE ADMIN</button> : null : null}
                                    </div>
                                </>
                                :
                                null
                            }
                            {adminPopup
                                ? currentUser.displayName === props.SelectedGroupData.Admin &&
                                <>
                                    <div className="flex flex-col mt-20 bg-gray-400 p-10 rounded-2xl w-[90%]">
                                        <h1 className="text-3xl text-teal-400 font-black underline uppercase">CHOOSE ADMIN</h1>
                                        {props.SelectedGroupData.Members?.map(m => (
                                            <>
                                                {m.displayName === props.SelectedGroupData.Admin ? null :
                                                    <li className="flex relative items-center gap-2 overflow-x-hidden cursor-pointer bg-white dark:bg-slate-800 p-2 rounded-3xl transition duration-300 hover:shadow-3xl hover:z-10 active:shadow-inner mt-4">
                                                        <picture className="relative flex h-12 aspect-square">
                                                            <img className="h-full w-full object-cover block rounded-full" src={m.photoURL} alt="user" />
                                                        </picture>
                                                        <div className="flex flex-row w-full justify-between items-center text-center">
                                                            <header className="text-sm font-bold dark:text-white">{m.displayName}</header>
                                                            <button className="flex bg-lime-400 p-2 text-white rounded-xl cursor-pointer font-extrabold" onClick={() => ChangeAdmin(m)}>
                                                                ADMIN
                                                            </button>
                                                        </div>
                                                    </li>}
                                            </>
                                        ))}
                                    </div>
                                </>
                                :
                                null
                            }
                            <div className="flex flex-col mt-20 bg-gray-400 p-10 rounded-2xl w-full">
                                <h1 className="text-3xl text-teal-400 font-black underline uppercase">Created On</h1>
                                <h1 className="text-2xl text-slate-800 dark:text-white font-extrabold mt-4">{props.SelectedGroupData.DateCreated}</h1>
                            </div>
                            <div className="flex flex-col mt-20 bg-gray-400 p-10 rounded-2xl w-full">
                                <h1 className="text-3xl text-teal-400 font-black underline uppercase">Total Members</h1>
                                <h1 className="text-2xl text-slate-800 dark:text-white font-extrabold mt-4">{props.SelectedGroupData.MembersCount === 1 ? props.SelectedGroupData.MembersCount + " Member" : props.SelectedGroupData.MembersCount + " Members"}</h1>
                            </div>
                            <div className="flex flex-col mt-20 bg-gray-400 p-4 rounded-2xl w-full">
                                <h1 className="text-3xl text-teal-400 font-black underline uppercase">Members</h1>
                                {props.SelectedGroupData.Members?.map(m => (
                                    <>
                                        <li className="flex relative items-center gap-2 overflow-x-hidden cursor-pointer w-full bg-white dark:bg-slate-800 p-2 rounded-3xl transition duration-300 hover:shadow-3xl hover:z-10 active:shadow-inner mt-4">
                                            <picture className="relative flex h-12 aspect-square">
                                                <img className="h-full w-full object-cover block rounded-full" src={m.photoURL} alt="user" />
                                            </picture>
                                            <div className="flex flex-row w-full justify-between items-center text-center">
                                                <header className="text-sm font-bold dark:text-white">{m.displayName}</header>
                                                {m.displayName === props.SelectedGroupData.Admin ? null :
                                                    <>
                                                        {m.displayName === currentUser.displayName ?
                                                            <button className="flex bg-red-400 p-2 text-white rounded-xl cursor-pointer font-extrabold" onClick={() => LeaveGroup(m)}>
                                                                LEAVE
                                                            </button>
                                                            :
                                                            currentUser.displayName === props.SelectedGroupData.Admin ?
                                                                <button button className="flex bg-red-400 p-2 text-white rounded-xl cursor-pointer font-extrabold" onClick={() => RemoveMember(m)}>
                                                                    REMOVE
                                                                </button>
                                                                :
                                                                <button button className="flex bg-red-400 p-2 text-white rounded-xl cursor-pointer font-extrabold" onClick={() => swal("Only Admin Can Remove Participants Of This Group!!")}>
                                                                    REMOVE
                                                                </button>
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </li >
                                    </>
                                ))}
                            </div>
                        </div>
                    </main>
                </section >
            }
        </>
    )
}

export default GroupSettings