import React, { useContext, useState } from 'react';
import FileInput from "../Images/folder.png";
import TopBarProgress from "react-topbar-progress-indicator";
import LoadingBar from 'react-top-loading-bar';
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from 'react-router-dom';
import { storage, db } from '../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";
import { getAuth, updateProfile } from "firebase/auth";
const auth = getAuth();

export const ChangeProfilePicture = (props) => {
  // document.title = "Drake Chat • Change Profile Picture"
  if (props.SendMsgCount === 0) {
    document.title = "Drake Chat • Change Profile Picture"
  } else {
    document.title = "(" + props.SendMsgCount + ") Drake Chat • Change Profile Picture"
  }
  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progresspercent, setProgressPercent] = useState();
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [text, setText] = useState("");

  const HandleFormSubmit = async (e) => {

    setLoading(true);
    e.preventDefault();
    const File = e.target[0].files[0];

    try {
      const storageRef = ref(storage, "UsersProfilePicture/" + currentUser.uid);
      const uploadTask = uploadBytesResumable(storageRef, File);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          const roundedprogress = Math.round(progress);
          setProgressPercent(roundedprogress);
        },
        (error) => {
          setErr(true);
          setLoading(false);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL4) => {
            updateProfile(auth.currentUser, {
              photoURL: downloadURL4
            }).then(() => {
              updateDoc(doc(db, "Users", currentUser.uid), {
                photoURL: downloadURL4
              });
              setText(null)
              setLoading(false);
              // window.location.reload()
            }).catch((error) => {
              setErr(true);
              setLoading(false);
            });
          }
          );

        });

    } catch (err) {
      setErr(true);
      setLoading(false);
    }
  }

  const handleImageChange = e => {
    // setImg(e.target.files[0])
    setText(e.target.files[0].name)
  }

  TopBarProgress.config({
    barThickness: 8
  });

  if (!currentUser) {
    navigate("/")
  }


  return (
    <>
      {loading && <LoadingBar color='linear-gradient(45deg,#ffbc00,#00d0ff ,#ff0058)' shadow={true} progress={progresspercent} onLoaderFinished={() => setProgressPercent(0)} height={4} style={{ "border-radius": "44px" }} />}
      <section className=" w-full absolute z-20 bg-transparent backdrop-blur-2xl rounded-2xl h-full left-0 top-0 p-4 overflow-hidden lg:block lg:relative lg:w-3/5 xl:w-3/4">
        <header className="flex justify-between px-2 md:px-4 lg:px-6">
          <div className="flex gap-8 align-center justify-center text-center">
            <span className="my-auto cursor-pointer " id="close-chat" onClick={() => props.onChange("Home")}>
              <i className="fi fi-rr-angle-small-left flex bg-violet-200 dark:bg-slate-800 dark:text-white p-2 rounded-lg text-violet-700 transition duration-500 hover:shadow-xl active:shadow-inner"></i>
            </span>
          </div>
        </header>
        <main className="bg-gray-200 dark:bg-slate-800 w-full h-[90%] mt-4 p-2 !pr-2 rounded-2xl md:p-4 lg:p-6 justify-center align-center text-center items-center">
          <div className="w-full h-full relative">
            <div className="w-full h-[90%] flex flex-col gap-4 pr-2 overflow-y-scroll overflow-x-hidden justify-center align-center text-center items-center">
              <h1 className="font-extrabold text-base md:text-xl break-all whitespace-nowrap dark:text-white">CHANGE PROFILE PICTURE</h1>
              <form onSubmit={HandleFormSubmit} className="justify-center align-center text-center items-center flex flex-col p-8" style={{ "border-radius": "44px", "background-color": "rgba(0,0,0,0.2)", "backdrop-filter": "blur('44px')" }}>
                <input accept="image/png, image/jpeg" type="file" placeholder="Enter Your Profile Picture" required id="File" style={{ display: "none" }} onChange={(e) => handleImageChange(e)} className="bg-transparent backdrop-blur-3xl" />
                <label htmlFor="File" className="justify-center flex flex-col items-center text-center">
                  <img src={FileInput} style={{ height: '40px', width: '40px' }} alt="Select A Profile Pic" className="cursor-pointer" />
                  <span className="font-extrabold text-white text-xl uppercase mt-8">Upload A Profile Picture</span>
                </label>
                <textbox readonly className="text-slate-800 dark:text-white">{text}</textbox>
                {loading && <button disabled="true" className="cursor-pointer text-white text-xl uppercase font-extrabold rounded-full bg-red-400 p-4 mt-8" style={{}}>Uploading</button>}
                {!loading && <button className="cursor-pointer text-white text-xl uppercase font-extrabold rounded-full bg-red-400 p-4 mt-8" style={{}}>Upload</button>}
                {!loading && err && <span style={{ color: 'red' }}>Something Went Wrong!!</span>}
              </form>
            </div>
          </div>
        </main>
      </section>
      {/* </div> */}
    </>
  )
}