import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

export default function PageNotFound() {
  const navigate = useNavigate();
  return (
    <>
      <body className="errorpage">
        <title>404 | Error Page Not Found</title>
        <h1>404 : Error Page</h1>
        <p className="zoom-area">
          <b>LOST ={">"} </b> You Have Been Redirected To a Page Which Does Not
          Exist.{" "}
        </p>
        <section className="error-container">
          <span className="four">
            <span className="screen-reader-text">4</span>
          </span>
          <span className="zero">
            <span className="screen-reader-text">0</span>
          </span>
          <span className="four">
            <span className="screen-reader-text">4</span>
          </span>
        </section>
        <div className="link-container">
          <button onClick={() => navigate(-1)} className="more-link-button">
            <FaArrowLeft /> Go Back
          </button>
          <Link to="/" className="more-link">
            Return Home
          </Link>
        </div>
      </body>
    </>
  );
}
