import React, { useState, useContext, useEffect } from 'react';
// import Default_Profile_Pic from "../Images/Default_Profile_Pic.png";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth, storage, db, Groupsdb, UserChatsdb, MessageDatadb } from '../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { doc, onSnapshot, setDoc } from "firebase/firestore";
import { useNavigate, Link } from 'react-router-dom';
// import ProgressBar from "@ramonak/react-progress-bar";
import TopBarProgress from "react-topbar-progress-indicator";
import LoadingBar from 'react-top-loading-bar';
import { AuthContext } from "../context/AuthContext";
import axios from 'axios';
import swal from '@sweetalert/with-react';

export const Register = () => {
  document.title = "Drake Chat • Register"

  const [err, setErr] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  // const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [progresspercent, setProgressPercent] = useState();
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [text, setText] = useState("");
  const [pfpFile, setPFPFile] = useState(null);
  const [ip, setIP] = useState('');

  const getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    // console.log(res.data);
    setIP(res.data.IPv4)
  }

  useEffect(() => {
    getData()
  }, [])

  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "Development", "Maintenance"), (doc) => {
      setUpdating(doc.data().Updating);
    });

    return () => {
      unsub();
    };
  }, [])

  // const onImageEdit = async (imgUrl) => {
  //   const response = await fetch(imgUrl);
  //   const blob = await response.blob();
  //   const file = new File([blob], "Default_Profile_Pic.png", {
  //     type: blob.type,
  //   });
  //   console.log(file)
  // }
  // onImageEdit("https://firebasestorage.googleapis.com/v0/b/drake-parker-s-chat-app.appspot.com/o/UsersProfilePicture%2FDefault_Profile_Pic.png")

  const HandleSubmit = async (e) => {
    if (pfpFile === null) {
      setLoading(false);
      e.preventDefault();
      swal("Profile Photo Required")
    } else {
      if (updating) {
        e.preventDefault();
        swal("Drake Chat Is In Maintenance Phase. Sorry For The Inconvenience Caused.")
      } else {
        setLoading(true);
        e.preventDefault();
        const displayName = e.target[0].value;
        const EMail = e.target[1].value;
        const Password = e.target[2].value;
        const File = pfpFile;

        if (File !== null) {
          try {
            const res = await createUserWithEmailAndPassword(auth, EMail, Password);

            const storageRef = ref(storage, "UsersProfilePicture/" + res.user.uid);
            const uploadTask = uploadBytesResumable(storageRef, File);
            uploadTask.on(
              'state_changed',
              (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                const roundedprogress = Math.round(progress);
                setProgressPercent(roundedprogress);
              },
              (error) => {
                setErr(true);
                setLoading(false);
              },
              () => {
                getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                  // console.log('File available at', downloadURL);
                  await updateProfile(res.user, {
                    displayName,
                    photoURL: downloadURL,
                  });
                  await setDoc(doc(db, "Users", res.user.uid), {
                    uid: res.user.uid,
                    displayName,
                    EMail,
                    photoURL: downloadURL,
                    Status: "Active Now",
                    BgImageURL: "https://firebasestorage.googleapis.com/v0/b/drake-parker-s-chat-app.appspot.com/o/ChatBackgroundImage%2FHome_Bg.jpg?alt=media&token=4d1bcf90-bb79-4b7b-a248-bcafaebb68d4",
                    Password: Password,
                    Device_IP: ip,
                    NoUserChats: true,
                  });
                  await setDoc(doc(UserChatsdb, "UserChats", res.user.uid), {});
                  await setDoc(doc(Groupsdb, "GroupsData", res.user.uid), {});
                  await setDoc(doc(MessageDatadb, "MessageData", res.user.uid), {
                    MessageUnSeen: [],
                    InChatWindow: "",
                  });
                  // await setDoc(doc(TypingStatusdb, "TypingStatus", res.user.uid), {
                  //   CurrentChatID: "",
                  //   TypingStatus: false,
                  // });
                  navigate("/")
                }
                );

              });

          } catch (err) {
            setErr(true);
            setErrorCode(err.code);
            // setErrorMessage(err.message);
            setLoading(false);
          }
        } else {
          try {
            const res = await createUserWithEmailAndPassword(auth, EMail, Password);

            await updateProfile(res.user, {
              displayName,
              photoURL: "https://firebasestorage.googleapis.com/v0/b/drake-parker-s-chat-app.appspot.com/o/UsersProfilePicture%2FDefault_Profile_Pic.png?alt=media&token=3bb429a2-9a04-41a7-a532-ea9eb84675bc",
            });
            await setDoc(doc(db, "Users", res.user.uid), {
              uid: res.user.uid,
              displayName,
              EMail,
              photoURL: "https://firebasestorage.googleapis.com/v0/b/drake-parker-s-chat-app.appspot.com/o/UsersProfilePicture%2FDefault_Profile_Pic.png?alt=media&token=3bb429a2-9a04-41a7-a532-ea9eb84675bc",
              Status: "Active Now",
              BgImageURL: "https://firebasestorage.googleapis.com/v0/b/drake-parker-s-chat-app.appspot.com/o/ChatBackgroundImage%2FHome_Bg.jpg?alt=media&token=4d1bcf90-bb79-4b7b-a248-bcafaebb68d4",
              Password: Password,
              Device_IP: ip,
              NoUserChats: true,
            });
            await setDoc(doc(UserChatsdb, "UserChats", res.user.uid), {});
            await setDoc(doc(Groupsdb, "GroupsData", res.user.uid), {});
            await setDoc(doc(MessageDatadb, "MessageData", res.user.uid), {
              MessageUnSeen: [],
              InChatWindow: "",
            });
            // await setDoc(doc(TypingStatusdb, "TypingStatus", res.user.uid), {
            //   CurrentChatID: "",
            //   TypingStatus: false,
            // });
            navigate("/")

          } catch (err) {
            setErr(true);
            setLoading(false);
          }
        }
      }
    }
  }

  const handleImageChange = e => {
    const validExtensions = ["image/jpg", "image/png", "image/jpeg"]
    if (!validExtensions.includes(e.target.files[0].type)) {
      swal("File Format Not Supported")
      setText("");
      setLoading(false);
      setPFPFile(null);
      return false
    } else {
      if (e.target.files[0].size > 10 * 1024 * 1024) {
        swal("File Size Should Be Less Than 10 MB")
        setText("");
        setLoading(false);
        setPFPFile(null);
        return false
      } else {
        setText(e.target.files[0].name)
        setPFPFile(e.target.files[0])
      }
    }
  }

  TopBarProgress.config({
    barThickness: 8
  });

  if (!loading) {
    if (currentUser) {
      navigate("/")
    }
  }

  // if (errorMessage) {
  //   return errorMessage
  // }

  return (
    <>
      <body id="FormBody">
        {loading && <LoadingBar color='linear-gradient(45deg,#ffbc00,#00d0ff ,#ff0058)' shadow={true} progress={progresspercent} onLoaderFinished={() => setProgressPercent(0)} height={4} style={{ "border-radius": "44px" }} />}
        <div className="login">
          <div className="login__content">
            <div className="login__img">
              <img src="https://raw.githubusercontent.com/bedimcode/responsive-login-signin-signup/b3c2eaa19d76624092bd606d28fbd616d539de92/assets/img/img-login.svg" alt="" />
            </div>

            <div className="login__forms loginform_reg">
              <form onSubmit={HandleSubmit} className="login__registre" id="login-in">
                <h1 className="login__title">Create Account</h1>

                <div className="login__box">
                  <i className="bx bx-user login__icon"></i>
                  <input type="text" placeholder="Username" className="login__input" required />
                </div>

                <div className="login__box">
                  <i className="bx bx-at login__icon" ></i>
                  <input type="email" placeholder="E-Mail" className="login__input" required />
                </div>

                <div className="login__box">
                  <i className="bx bx-lock-alt login__icon"></i>
                  <input type="password" placeholder="Password" className="login__input" required />
                </div>

                <div className="login__box_pfp">
                  <input type="file" accept="image/png, image/jpeg" placeholder="Image" className="login__input" id="File" style={{ display: "none" }} onChange={(e) => handleImageChange(e)} />
                  <label htmlFor="File" className="flex flex-row space-x-4">
                    <i className="bx bxs-file-image login__icon cursor-pointer"></i>
                    <h1 className="login__input cursor-pointer break-all">{text === "" ? "Add A Profile Picture" : text}</h1>
                  </label>
                </div>
                {!loading && err && <span className="text-red-500 uppercase font-extrabold">Something went wrong</span>}<br />
                {!loading && err && <span className="text-red-500 uppercase font-extrabold">{errorCode}</span>}
                {/* {!loading && err && <span className="text-red-500 uppercase font-extrabold">{errorMessage}</span>} */}
                {loading && <button className="login__button cursor-pointer w-full" disabled="true">Creating Account</button>}
                {!loading && <button className="login__button cursor-pointer w-full">Create Account</button>}

                {!loading &&
                  <div>
                    <span className="login__account">Already a User? </span>
                    <span className="login__signin" id="sign-up"><Link to="/Login">Sign In</Link></span>
                  </div>
                }
              </form>
            </div>
          </div>
        </div>
      </body>
    </>
  )
}
