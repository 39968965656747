/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
// import { ChatContext } from "../context/ChatContext";
// import { ForwardMsgContext } from "../context/ForwardMsgContext";
import {
    arrayUnion,
    doc,
    updateDoc,
    // increment,
} from "firebase/firestore";
import { Chatsdb, GroupChatsdb } from "../firebase";
import { v4 as uuid } from "uuid";

const Forward = (props) => {
    const { currentUser } = useContext(AuthContext);
    // const { dispatch, data } = useContext(ChatContext);
    // const { dispatch, data } = useContext(ForwardMsgContext);

    const [loading, setLoading] = useState(true);
    const [text, setText] = useState(null);
    const [img, setImg] = useState(null);
    const [music, setMusic] = useState(null);
    const [video, setVideo] = useState(null);
    const [document, setDocument] = useState(null);

    useEffect(() => {
        if (loading) {
            setTimeout(() => {
                setLoading(false);
            }, 2000);
        }
    }, [loading]);

    // const sleep = ms => new Promise(
    //     resolve => setTimeout(resolve, ms)
    // );

    const [combinedCurrentDateTime, setCombinedCurrentDateTime] = useState(null);

    useEffect(() => {
        const interval = setInterval(() => {
            var current = new Date();
            var CurrentTime = current.toLocaleString([], {
                hour12: true,
                hour: "2-digit",
                minute: "2-digit",
            });
            var CurrentDate = current.toLocaleDateString();
            setCombinedCurrentDateTime(CurrentDate + " " + CurrentTime);
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const [msgSent, setMsgSent] = useState(null)
    const [forwardMsgSending, setForwardMsgSending] = useState(null)

    const HandleSend = async (userData) => {
        if (img) {
            setForwardMsgSending(userData.uid);
            await updateDoc(doc(Chatsdb, "Chats", userData.chatId), {
                Messages: arrayUnion({
                    id: uuid(),
                    senderId: currentUser.uid,
                    receiverId: userData.uid,
                    img: props.CurrentMessageValue,
                    MessageSentTimestamp: combinedCurrentDateTime,
                    text: "",
                    // forward: true,
                }),
            });
            // setForwardMsgSen
            setForwardMsgSending(null)
            setMsgSent(userData.uid)
        } else if (music) {
            setForwardMsgSending(userData.uid);
            // setForwardMsgSending(data.user.uid);
            await updateDoc(doc(Chatsdb, "Chats", userData.chatId), {
                Messages: arrayUnion({
                    id: uuid(),
                    senderId: currentUser.uid,
                    receiverId: userData.uid,
                    music: props.CurrentMessageValue,
                    MessageSentTimestamp: combinedCurrentDateTime,
                    text: "",
                    // forward: true,
                }),
            });
            // setForwardMsgSen
            setForwardMsgSending(null)
            setMsgSent(userData.uid)
        } else if (video) {
            setForwardMsgSending(userData.uid);
            // setForwardMsgSending(data.user.uid);
            await updateDoc(doc(Chatsdb, "Chats", userData.chatId), {
                Messages: arrayUnion({
                    id: uuid(),
                    senderId: currentUser.uid,
                    receiverId: userData.uid,
                    video: props.CurrentMessageValue,
                    MessageSentTimestamp: combinedCurrentDateTime,
                    text: "",
                    // forward: true,
                }),
            });
            // setForwardMsgSen
            setForwardMsgSending(null)
            setMsgSent(userData.uid)
        } else if (document) {
            setForwardMsgSending(userData.uid);
            // setForwardMsgSending(data.user.uid);
            await updateDoc(doc(Chatsdb, "Chats", userData.chatId), {
                Messages: arrayUnion({
                    id: uuid(),
                    senderId: currentUser.uid,
                    receiverId: userData.uid,
                    document: props.CurrentMessageValue,
                    MessageSentTimestamp: combinedCurrentDateTime,
                    text: "",
                    // forward: true,
                }),
            });
            // setForwardMsgSen
            setForwardMsgSending(null)
            setMsgSent(userData.uid)
        } else if (text) {
            setForwardMsgSending(userData.uid);
            // setForwardMsgSending(data.user.uid);
            await updateDoc(doc(Chatsdb, "Chats", userData.chatId), {
                Messages: arrayUnion({
                    id: uuid(),
                    text: props.CurrentMessageValue,
                    senderId: currentUser.uid,
                    receiverId: userData.uid,
                    MessageSentTimestamp: combinedCurrentDateTime,
                    // forward: true,
                }),
            });
            setForwardMsgSending(null)
            setMsgSent(userData.uid)
        }

        // await updateDoc(doc(db, "MessageData", userData.uid), {
        //     [userData.chatId + ".seen"]: false,
        //     [userData.chatId + ".UnreadMessageCount"]: increment(1),
        // });
    };

    const HandleSendGroup = async (groupData) => {
        if (img) {
            setForwardMsgSending(groupData.id);
            await updateDoc(doc(GroupChatsdb, "GroupChats", groupData.id), {
                Messages: arrayUnion({
                    id: uuid(),
                    senderId: currentUser.uid,
                    // receiverId: groupData.id,
                    img: props.CurrentMessageValue,
                    MessageSentTimestamp: combinedCurrentDateTime,
                    text: "",
                    // forward: true,
                }),
            });
            // setForwardMsgSen
            setForwardMsgSending(null)
            setMsgSent(groupData.id)
        } else if (music) {
            setForwardMsgSending(groupData.id);
            // setForwardMsgSending(data.user.uid);
            await updateDoc(doc(GroupChatsdb, "GroupChats", groupData.id), {
                Messages: arrayUnion({
                    id: uuid(),
                    senderId: currentUser.uid,
                    // receiverId: groupData.id,
                    music: props.CurrentMessageValue,
                    MessageSentTimestamp: combinedCurrentDateTime,
                    text: "",
                    // forward: true,
                }),
            });
            // setForwardMsgSen
            setForwardMsgSending(null)
            setMsgSent(groupData.id)
        } else if (video) {
            setForwardMsgSending(groupData.id);
            // setForwardMsgSending(data.user.uid);
            await updateDoc(doc(GroupChatsdb, "GroupChats", groupData.id), {
                Messages: arrayUnion({
                    id: uuid(),
                    senderId: currentUser.uid,
                    // receiverId: groupData.id,
                    video: props.CurrentMessageValue,
                    MessageSentTimestamp: combinedCurrentDateTime,
                    text: "",
                    // forward: true,
                }),
            });
            // setForwardMsgSen
            setForwardMsgSending(null)
            setMsgSent(groupData.id)
        } else if (document) {
            setForwardMsgSending(groupData.id);
            // setForwardMsgSending(data.user.uid);
            await updateDoc(doc(GroupChatsdb, "GroupChats", groupData.id), {
                Messages: arrayUnion({
                    id: uuid(),
                    senderId: currentUser.uid,
                    // receiverId: groupData.id,
                    document: props.CurrentMessageValue,
                    MessageSentTimestamp: combinedCurrentDateTime,
                    text: "",
                    // forward: true,
                }),
            });
            // setForwardMsgSen
            setForwardMsgSending(null)
            setMsgSent(groupData.id)
        } else if (text) {
            setForwardMsgSending(groupData.id);
            // setForwardMsgSending(data.user.uid);
            await updateDoc(doc(GroupChatsdb, "GroupChats", groupData.id), {
                Messages: arrayUnion({
                    id: uuid(),
                    text: props.CurrentMessageValue,
                    senderId: currentUser.uid,
                    // receiverId: groupData.id,
                    MessageSentTimestamp: combinedCurrentDateTime,
                    // forward: true,
                }),
            });
            setForwardMsgSending(null)
            setMsgSent(groupData.id)
        }

        // await updateDoc(doc(db, "MessageData", userData.uid), {
        //     [userData.chatId + ".seen"]: false,
        //     [userData.chatId + ".UnreadMessageCount"]: increment(1),
        // });
    };


    // const ChangeUser = async (u) => {
    //     await dispatch({ type: "CHANGE_USER", payload: u })
    //     setForwardMsgSending(data.user.uid);
    //     // await sleep(1000);
    //     HandleSend()
    // };

    useEffect(() => {
        // dispatch({ type: "DELETE_USER" })
        if (props.CurrentMsgContent === "Forward Text") {
            setText(props.CurrentMessageValue)
            setText(props.CurrentMessageValue)
        } else if (props.CurrentMsgContent === "Forward Audio") {
            setMusic(props.CurrentMessageValue)
            setMusic(props.CurrentMessageValue)
        } else if (props.CurrentMsgContent === "Forward Video") {
            setVideo(props.CurrentMessageValue)
            setVideo(props.CurrentMessageValue)
        } else if (props.CurrentMsgContent === "Forward Document") {
            setDocument(props.CurrentMessageValue)
            setDocument(props.CurrentMessageValue)
        } else if (props.CurrentMsgContent === "Forward Image") {
            setImg(props.CurrentMessageValue)
            setImg(props.CurrentMessageValue)
        }
    }, [])

    return (
        <>
            <section className="bg-transparent overflow-y-auto backdrop-blur-2xl rounded-2xl p-2 w-full h-full flex flex-col gap-2 lg:w-2/5 xl:w-3/4">
                <div className="h-[60%]">
                    <h3 className="font-extrabold text-xl text-slate-800 bg-red-400 rounded-full dark:text-white uppercase mt-2 justify-center text-center">
                        Message Forwarding
                    </h3>
                    <h2 className="font-extrabold text-xl text-slate-800 dark:text-white uppercase mt-2 justify-center text-center">
                        {props.CurrentMsgContent}
                    </h2>
                    <ul className="mt-4 flex flex-col gap-4 overflow-y-scroll overflow-x-hidden h-[90%]" id="messages-list">
                        <>
                            {Object.entries(props.SendChatData)?.sort((a, b) => b[1].date - a[1].date).map((chat) => (
                                <>
                                    <div key={chat[0]}>
                                        <li id="SelectUser" className="flex relative items-center gap-2 overflow-x-hidden bg-white dark:bg-slate-800 p-2 rounded-3xl transition duration-300 hover:shadow-3xl hover:z-10 active:shadow-inner">
                                            <picture className="relative flex h-12 aspect-square">
                                                <img className="h-full w-full object-cover block rounded-full" src={chat[1].userInfo.photoURL} alt="user" />
                                            </picture>
                                            <div className="flex flex-row w-full justify-between items-center text-center">
                                                <header className="text-2xl font-bold dark:text-white">
                                                    {chat[1].userInfo.displayName}
                                                </header>
                                                {/* {msgSent
                                                    ?
                                                    <>
                                                        {forwardMsgSending === chat[1].userInfo.uid
                                                            ?
                                                            <button className="flex bg-lime-500 py-2 px-4 text-white rounded-xl cursor-pointer font-extrabold">
                                                                SENT
                                                            </button>
                                                            :
                                                            <button className="flex bg-blue-500 py-2 px-4 text-white rounded-xl cursor-pointer font-extrabold" onClick={() => HandleSend(chat[1].userInfo)}>
                                                                SEND
                                                            </button>
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        {forwardMsgSending === chat[1].userInfo.uid
                                                            ?
                                                            <button className="flex bg-purple-500 py-2 px-4 text-white rounded-xl cursor-pointer font-extrabold">
                                                                SENDING
                                                            </button>
                                                            :
                                                            <button className="flex bg-blue-500 py-2 px-4 text-white rounded-xl cursor-pointer font-extrabold" onClick={() => HandleSend(chat[1].userInfo)}>
                                                                SEND
                                                            </button>
                                                        }
                                                    </>
                                                } */}
                                                {msgSent === chat[1].userInfo.uid
                                                    ?
                                                    <>
                                                        <button className="flex bg-lime-500 py-2 px-4 text-white rounded-xl cursor-pointer font-extrabold">
                                                            SENT
                                                        </button>
                                                    </>
                                                    :
                                                    <>
                                                        {forwardMsgSending === chat[1].userInfo.uid
                                                            ?
                                                            <button className="flex bg-purple-500 py-2 px-4 text-white rounded-xl cursor-pointer font-extrabold">
                                                                SENDING
                                                            </button>
                                                            :
                                                            <button className="flex bg-blue-500 py-2 px-4 text-white rounded-xl cursor-pointer font-extrabold" onClick={() => HandleSend(chat[1].userInfo)}>
                                                                SEND
                                                            </button>
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </li>
                                    </div>
                                </>
                            ))}
                            {Object.entries(props.SendGroupsData)?.sort((a, b) => b[1].date - a[1].date).map((group) => (
                                <>
                                    {/* {console.log(group)} */}
                                    <div key={group[0]} >
                                        <li id="SelectUser"
                                            className="flex relative items-center gap-2 overflow-x-hidden cursor-pointer bg-white dark:bg-slate-800 p-2 rounded-3xl transition duration-300 hover:shadow-3xl hover:z-10 active:shadow-inner">
                                            <picture className="relative flex h-12 aspect-square">
                                                <img className="h-full w-full object-cover block rounded-full" src={group[1].displayPic} alt="user" />
                                            </picture>
                                            <div className="flex flex-row w-full justify-between items-center text-center">
                                                <header className="text-2xl font-bold dark:text-white">
                                                    {group[1].Name}
                                                </header>
                                                {msgSent === group[1].id
                                                    ?
                                                    <>
                                                        <button className="flex bg-lime-500 py-2 px-4 text-white rounded-xl cursor-pointer font-extrabold">
                                                            SENT
                                                        </button>
                                                    </>
                                                    :
                                                    <>
                                                        {forwardMsgSending === group[1].id
                                                            ?
                                                            <button className="flex bg-purple-500 py-2 px-4 text-white rounded-xl cursor-pointer font-extrabold">
                                                                SENDING
                                                            </button>
                                                            :
                                                            <button className="flex bg-blue-500 py-2 px-4 text-white rounded-xl cursor-pointer font-extrabold" onClick={() => HandleSendGroup(group[1])}>
                                                                SEND
                                                            </button>
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </li>
                                    </div>
                                </>
                            ))}
                        </>
                    </ul>
                </div>
                <div className="flex h-[40%] items-center justify-center align-center text-center">
                    <h3 className="font-extrabold text-xl dark:text-white bg-red-400 rounded-full p-2 max-w-max cursor-pointer uppercase mt-10 justify-center text-center" onClick={props.ExitForwardMessage}>
                        CLOSE
                    </h3>
                </div>
                {/* <div id="toast-simple" class="flex items-center w-full max-w-xs p-4 space-x-4 text-gray-500 bg-white divide-x divide-gray-200 rounded-lg shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800" role="alert">
                    <svg aria-hidden="true" class="w-5 h-5 text-blue-600 dark:text-blue-500" focusable="false" data-prefix="fas" data-icon="paper-plane" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M511.6 36.86l-64 415.1c-1.5 9.734-7.375 18.22-15.97 23.05c-4.844 2.719-10.27 4.097-15.68 4.097c-4.188 0-8.319-.8154-12.29-2.472l-122.6-51.1l-50.86 76.29C226.3 508.5 219.8 512 212.8 512C201.3 512 192 502.7 192 491.2v-96.18c0-7.115 2.372-14.03 6.742-19.64L416 96l-293.7 264.3L19.69 317.5C8.438 312.8 .8125 302.2 .0625 289.1s5.469-23.72 16.06-29.77l448-255.1c10.69-6.109 23.88-5.547 34 1.406S513.5 24.72 511.6 36.86z"></path></svg>
                    <div class="pl-4 text-sm font-normal">Message sent successfully.</div>
                </div> */}
            </section >
        </>
    );
};

export default Forward;
